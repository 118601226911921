.formInline {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  margin: 5px;
}

.formInline .buttongroup {
  display: flex;
  justify-content: center;
  margin-top: 0.9rem;
  margin-left: 1rem;
}
